<template>
  <v-col cols="12">
    <v-sheet outlined>
      <v-container fluid>
        <v-row>
          <v-col cols="12">
            <span class="font-weight-bold">DUT Configuration</span>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" md="6">
            <v-select
              dense
              :items="tpLayers"
              label="TestPoint Layer *"
              item-text="description"
              item-value="pk"
              v-model.number="tp_layer"
              :disabled="!isAdmin"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              dense
              label="DUT Rotation (degress) *"
              name="rotation"
              type="number"
              v-model.number="rotation"
              :disabled="!isAdmin"
              step="1"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <span class="font-weight-bold">Placement</span>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" md="6">
            <v-text-field
              dense
              label="X-Offset (mm) *"
              name="xOffset"
              type="number"
              v-model.number="x_offset"
              :disabled="!isAdmin"
              step="0.0001"
              @change="computeValue('x_offset')"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              dense
              label="Y-Offset (mm) *"
              name="yOffset"
              type="number"
              v-model.number="y_offset"
              :disabled="!isAdmin"
              step="0.0001"
              @change="computeValue('y_offset')"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <span class="font-weight-bold">Panel Configuration</span>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" md="6">
            <v-text-field
              dense
              label="X-Quantity *"
              name="xQuantity"
              type="number"
              v-model.number="panel_x_num"
              :disabled="!isAdmin"
              step="1"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              dense
              label="X-Spacing (mm) *"
              name="xSpacing"
              type="number"
              v-model.number="panel_x_spacing"
              :disabled="!isAdmin"
              step="0.0001"
              @change="computeValue('panel_x_spacing')"
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" md="6">
            <v-text-field
              dense
              label="Y-Quantity *"
              name="yQuantity"
              type="number"
              v-model.number="panel_y_num"
              :disabled="!isAdmin"
              step="1"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              dense
              label="Y-Spacing (mm) *"
              name="ySpacing"
              type="number"
              v-model.number="panel_y_spacing"
              :disabled="!isAdmin"
              step="0.0001"
              @change="computeValue('panel_y_spacing')"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <span class="font-weight-bold">Viewer Settings</span>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" md="12">
            <v-select
              dense
              :items="renderSettings"
              label="Cad Model *"
              item-text="description"
              item-value="pk"
              v-model="render_setting"
              :disabled="!isAdmin"
              data-cy="dut-config-viewer-settings-input"
            />
          </v-col>
        </v-row>
        <v-row class="mt-4">
          <v-col cols="12">
            <v-btn
              block
              color="secondary"
              @click="
                gotoConfigure({
                  pathName: 'configure',
                  projectId: $route.params.id,
                  dutId: defaultDut,
                  newtab: false,
                })
              "
              @contextmenu="
                activateMenu({
                  nextRoute: {
                    pathName: 'configure',
                    projectId: $route.params.id,
                    dutId: defaultDut,
                    newtab: true,
                  },
                  nextAction: 'gotoConfigure',
                  event: $event
                })
              "
              >Fixture Component Configuration</v-btn
            >
          </v-col>
          <v-col cols="12">
            <v-btn
              block
              color="secondary"
              :disabled="!isAdmin"
              @click="onSaveForm"
              data-cy="dut-config-update-render-button"
              >Update 3D Render</v-btn
            >
          </v-col>
          <v-col cols="12">
            <v-btn
              block
              color="warning"
              data-cy="dut-config-fulfillment-button"
              @click="
                gotoNextRoute({
                  pathName: 'fulfillment',
                  pathId: $route.params.id,
                  newtab: false,
                })
              "
              @contextmenu="
                activateMenu({
                  nextRoute: {
                    pathName: 'fulfillment',
                    pathId: $route.params.id,
                    newtab: true,
                  },
                  nextAction: 'gotoNextRoute',
                  event: $event
                })
              "
              >View BOM and Purchasing Details</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    <BaseSubMenuPopup
      :showMenu="showMenu"
      :xCoord="xCoord"
      :yCoord="yCoord"
      :nextAction="nextAction"
      :buttonText="buttonText"
      @onClick="subMenuClickHandler"
      @onCopyUrl="subMenuCopyUrl"
    />
  </v-col>
</template>
<script>
import SubMenuPopup from "@/mixins/SubMenuPopup";
import { mapGetters } from "vuex";
export default {
  name: "DutConfigForm",
  mixins: [SubMenuPopup],
  props: {
    dutConfig: {
      type: Object,
      default: null,
    },
    renderSet: {
      type: Number,
      default: null,
    },
    isInitialState: {
      type: Boolean,
      required: true,
    },
    isAdmin: {
      type: Boolean,
      required: true,
    },
    defaultDut: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      tp_layer: this.dutConfig.tp_layer,
      rotation: this.dutConfig.rotation,
      x_offset: this.dutConfig.x_offset.toFixed(4),
      y_offset: this.dutConfig.y_offset.toFixed(4),
      panel_x_num: this.dutConfig.panel_x_num,
      panel_x_spacing: this.dutConfig.panel_x_spacing.toFixed(4),
      panel_y_num: this.dutConfig.panel_y_num,
      panel_y_spacing: this.dutConfig.panel_y_spacing.toFixed(4),
      render_setting: this.renderSet,
      rules: {
        requireCheck: [(v) => !!v || "Input is required"],
      },
    };
  },
  computed: {
    ...mapGetters({
      tpLayers: "dutconfigs/testpointLayers",
      renderSettings: "dutconfigs/renderSettings",
    }),
  },
  methods: {
    onGenerate() {
      this.$emit("generate");
    },
    onConfigure() {
      this.$emit("configure");
    },
    onSaveForm() {
      const formPayload = {
        pk: this.dutConfig.pk,
        data: {
          tp_layer: this.tp_layer,
          rotation: this.rotation,
          x_offset: this.x_offset,
          y_offset: this.y_offset,
          panel_x_num: this.panel_x_num,
          panel_y_num: this.panel_y_num,
          panel_x_spacing: this.panel_x_spacing,
          panel_y_spacing: this.panel_y_spacing,
        },
        render_setting: this.render_setting,
      };
      this.$emit("saveData", {
        payload: formPayload,
        formUpdated: true,
      });
    },
    refreshData() {
      setTimeout(() => {
        this.tp_layer = this.dutConfig.tp_layer;
        this.rotation = this.dutConfig.rotation;
        this.x_offset = this.dutConfig.x_offset.toFixed(4);
        this.y_offset = this.dutConfig.y_offset.toFixed(4);
        this.panel_x_num = this.dutConfig.panel_x_num;
        this.panel_x_spacing = this.dutConfig.panel_x_spacing.toFixed(4);
        this.panel_y_num = this.dutConfig.panel_y_num;
        this.panel_y_spacing = this.dutConfig.panel_y_spacing.toFixed(4);
        this.render_setting = this.renderSet;
      }, 100);
    },
    onInputString() {
      return "if (this.value > 2147483647) this.value = 2147483647; if (this.value < 0) this.value = 0;";
    },
    gotoConfigure({ pathName, projectId, dutId, newtab = false }) {
      this.$store.dispatch("projects/setProject", this.project);
      if (newtab) {
        let routeData = this.$router.resolve({
          name: pathName,
          params: { id: projectId, did: dutId },
        });
        window.open(routeData.href, "_blank");
      } else {
        this.$router.push({
          name: pathName,
          params: { id: projectId, did: dutId },
        });
      }
    },
    async gotoConfigureCopyUrl({ pathName, projectId, dutId }) {
      const routeFull = new URL(
        this.$router.resolve({
          name: pathName,
          params: { id: projectId, did: dutId },
        }).href,
        window.location.origin
      ).href;
      console.log(routeFull);
      await navigator.clipboard.writeText(routeFull);
    },
    gotoNextRoute({ pathName, pathId, newtab = false }) {
      if (newtab) {
        let routeData = this.$router.resolve({
          name: pathName,
          params: { id: pathId },
        });
        window.open(routeData.href, "_blank");
      } else {
        this.$router.push({
          name: pathName,
          params: { id: pathId },
        });
      }
    },
    async gotoNextRouteCopyUrl({ pathName, pathId }) {
      const routeFull = new URL(
        this.$router.resolve({
          name: pathName,
          params: { id: pathId },
        }).href,
        window.location.origin
      ).href;
      console.log(routeFull);
      await navigator.clipboard.writeText(routeFull);
    },
    computeValue(fieldName) {
      this[fieldName] = (this[fieldName] * 1).toFixed(4) ;
    },
  },
};
</script>
